import { useEffect } from "react";
import { supabase } from "../supabase";

function Debug() {
  useEffect(() => {
    supabase.auth.getUser().then(({ data }) => {
      console.log(data);
    });
  }, []);
  return (
    <div className="App">
      <header className="App-header">Success</header>
    </div>
  );
}

export default Debug;
