import { useState } from "react";
import Layout from "../../Layout";
import { Box, Button, MaskedInput, Form, FormField } from "grommet";
import { supabase } from "../../supabase";
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";

function EnterPhone(props) {
  const [value, setValue] = useState("");
  const { user } = useAuth();
  const redirect = useNavigate();
  return (
    <Layout>
      <Box align="center" justify="center" pad="large">
        <Box width="medium">
          <Form
            onChange={(nextValue, { touched }) => {
              console.log("Change", nextValue, touched);
              setValue(nextValue);
            }}
            onReset={() => setValue("")}
            onSubmit={async (event) => {
              event.preventDefault();
              const phoneNumberValue = event.value.phone.replace(/\D/g, "");
              const updates = {
                user_id: user.id,
                phone_number: phoneNumberValue,
              };
              await supabase.from("contact_detail").upsert(updates);
              console.log(
                "Submit",
                event.value,
                event.touched,
                phoneNumberValue
              );
              redirect("/");
              return;
            }}
          >
            <FormField label="Phone" htmlFor="phone" name="phone" required>
              <MaskedInput
                id="phone"
                name="phone"
                mask={[
                  { fixed: "(" },
                  {
                    length: 3,
                    regexp: /^[0-9]{1,3}$/,
                    placeholder: "5xx",
                  },
                  { fixed: ")" },
                  { fixed: " " },
                  {
                    length: 3,
                    regexp: /^[0-9]{1,3}$/,
                    placeholder: "xxx",
                  },
                  { fixed: "-" },
                  {
                    length: 4,
                    regexp: /^[0-9]{1,4}$/,
                    placeholder: "xxxx",
                  },
                ]}
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </FormField>
            <Box direction="row" justify="between" margin={{ top: "medium" }}>
              <Button type="submit" label="Continue" primary />
            </Box>
          </Form>
        </Box>
      </Box>
    </Layout>
  );
}

export default EnterPhone;
