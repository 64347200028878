import { useEffect, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { Navigate } from "react-router-dom";
import { supabase } from "../supabase";

export default function Login() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      console.log(session);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  if (!session) {
    return (
      <div style={{ margin: "25px" }}>
        <Auth
          redirectTo={window.location.origin}
          supabaseClient={supabase}
          appearance={{ theme: ThemeSupa }}
        />
      </div>
    );
  } else {
    return <Navigate to={"/"} />;
  }
}
