import { useAuth } from "../contexts/Auth";
import { supabase } from "../supabase";
import Layout from "../Layout";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Spinner } from "grommet";
import { Camera } from "grommet-icons";
import MyQr from "../components/MyQr";
import MyMatches from "../components/MyMatches";

function Me() {
  const { user } = useAuth();
  const redirect = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [qrValue, setQrValue] = useState("");

  useEffect(() => {
    const checkOnboarding = async () => {
      const response = await supabase
        .from("contact_detail")
        .select(`user_id, created_at, email, phone_number, linkedin_url`)
        .eq("user_id", user.id)
        .single();

      if (response.error?.code === "PGRST116") {
        const updates = {
          user_id: user.id,
          email: user.email,
        };
        await supabase.from("contact_detail").upsert(updates);
        redirect("/");
        return;
      }
      if (response.data["email"] === null) {
        const updates = {
          user_id: user.id,
          email: user.email,
        };
        await supabase.from("contact_detail").upsert(updates);
        redirect("/");
        return;
      }
      if (response.data["phone_number"] === null) {
        redirect("/onboarding/enter-phone");
        return;
      }
      if (response.data["linkedin_url"] === null) {
        redirect("/onboarding/enter-linkedin");
        return;
      }
      const origin = window.location.origin;
      const value = `${origin}/match/${user.id}`;
      setQrValue(value);
      setIsLoading(false);
    };
    checkOnboarding();
  }, [user, redirect]);

  return (
    <Layout>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Spinner
            border={[
              { side: "all", color: "background-contrast", size: "medium" },
              { side: "right", color: "brand", size: "medium" },
              { side: "top", color: "brand", size: "medium" },
              { side: "left", color: "brand", size: "medium" },
            ]}
          />
        </div>
      ) : (
        <div style={{ margin: "50px" }}>
          <MyQr value={qrValue} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              label="Scan"
              icon={<Camera />}
              primary
              onClick={() => {
                redirect("/scan");
              }}
            />
          </div>
          <MyMatches />
        </div>
      )}
    </Layout>
  );
}

export default Me;
