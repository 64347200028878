import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";
import Me from "./pages/Me";
import EnterPhone from "./pages/onboarding/EnterPhone";
import EnterLinkedin from "./pages/onboarding/EnterLinkedin";
import Login from "./pages/Login";
import Debug from "./pages/Debug";
import Scan from "./pages/Scan";
import Match from "./pages/Match";

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute />}>
        <Route path="/" element={<Me />} />
        <Route path="/onboarding/enter-phone" element={<EnterPhone />} />
        <Route path="/onboarding/enter-linkedin" element={<EnterLinkedin />} />
        <Route path={"/match/:id"} element={<Match />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/debug" element={<Debug />} />
      <Route path="/scan" element={<Scan />} />
    </Routes>
  );
}

export  default AppRoutes;
