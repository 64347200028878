import { supabase } from "../supabase";
import { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Grid,
  Spinner,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "grommet";

function MyMatches() {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const { data, error } = await supabase.functions.invoke("list-matches");

      if (error) {
        setData([]);
      }

      setData(data);
      setIsLoading(false);
    };

    fetch();
  }, []);

  const getAvatar = (person) => {
    if (person["avatar_url"]) {
      return <Avatar src={person["avatar_url"]} />;
    } else {
      return <Avatar background="brand">{person["full_name"][0]}</Avatar>;
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Spinner
            border={[
              { side: "all", color: "background-contrast", size: "medium" },
              { side: "right", color: "brand", size: "medium" },
              { side: "top", color: "brand", size: "medium" },
              { side: "left", color: "brand", size: "medium" },
            ]}
          />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Grid>
            <Box>
              <h2>🪪 My matches </h2>
            </Box>
            <Box direction="row">
              <Table>
                <TableBody>
                  {data.map((person, id) => {
                    return (
                      <TableRow key={id}>
                        <TableCell>{getAvatar(person)}</TableCell>
                        <TableCell scope="row">
                          <strong>{person["full_name"]}</strong>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Grid>
        </div>
      )}
    </>
  );
}

export default MyMatches;
