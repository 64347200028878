import { useState } from "react";
import Layout from "../../Layout";
import { Box, Button, Form, FormField, TextInput } from "grommet";
import { supabase } from "../../supabase";
import { useAuth } from "../../contexts/Auth";
import { useNavigate } from "react-router-dom";

function EnterLinkedin(props) {
  const [value, setValue] = useState("");
  const { user } = useAuth();
  const redirect = useNavigate();
  return (
    <Layout>
      <Box align="center" justify="center" pad="large">
        <Box width="medium">
          <Form
            onChange={(nextValue, { touched }) => {
              console.log("Change", nextValue, touched);
              setValue(nextValue);
            }}
            onReset={() => setValue("")}
            onSubmit={async (event) => {
              event.preventDefault();
              const updates = {
                user_id: user.id,
                linkedin_url: event.value.linkedin,
              };
              await supabase.from("contact_detail").upsert(updates);
              console.log("Submit", event.value, event.touched);
              redirect("/");
              return;
            }}
          >
            <FormField
              label="Linkedin"
              htmlFor="linkedin"
              name="linkedin"
              required
            >
              <TextInput
                id="linkedin"
                name="linkedin"
                value={value}
                onChange={(event) => setValue(event.target.value)}
              />
            </FormField>
            <Box direction="row" justify="between" margin={{ top: "medium" }}>
              <Button type="submit" label="Continue" primary />
            </Box>
          </Form>
        </Box>
      </Box>
    </Layout>
  );
}

export default EnterLinkedin;
