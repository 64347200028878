import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/Auth";
import AppRoutes from "./AppRoutes";

function App() {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
}

export default App;
