import { useRef, useEffect } from "react";
import QrScanner from "qr-scanner";
import Layout from "../Layout";

function Debug() {
  const videoRef = useRef(null);

  const onDecode = (result) => {
    console.log(result);
    const { data } = result;
    const includes = data.includes("match");
    if (includes) {
      window.location.href = data;
    }
  };

  useEffect(() => {
    const qrScanner = new QrScanner(
      videoRef.current,
      onDecode,
      { returnDetailedScanResult: true } // You can add more options here
    );

    qrScanner.start();

    return () => {
      qrScanner.stop();
    };
  }, []);

  return (
    <Layout>
      <video
        ref={videoRef}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          zIndex: -1,
        }}
      ></video>
    </Layout>
  );
}

export default Debug;
