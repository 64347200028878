import { useNavigate, useParams } from "react-router-dom";
import Layout from "../Layout";
import { useAuth } from "../contexts/Auth";
import { supabase } from "../supabase";
import SuccessAnimation from "actually-accessible-react-success-animation";
import { Button } from "grommet";
import { FormPrevious } from "grommet-icons";

function Match() {
  const { id } = useParams();
  const { user } = useAuth();
  const redirect = useNavigate();
  const addMatch = async () => {
    const updates = {
      target_user_id: id,
      source_user_id: user.id,
    };
    await supabase.from("match").upsert(updates);
  };

  addMatch();

  return (
    <Layout>
      <div
        id="live"
        aria-live="polite"
        style={{ opacity: 0, position: "fixed" }}
      />
      <SuccessAnimation
        text="You've matched! 😉"
        color="#5cb85c"
        liveRegion="live"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <Button
          label="Return"
          icon={<FormPrevious />}
          primary
          onClick={() => {
            redirect("/");
          }}
        />
      </div>
    </Layout>
  );
}

export default Match;
