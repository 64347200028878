import { useAuth } from "./contexts/Auth";
import { Avatar, Box, Button, Grommet, Header, Image, Text } from "grommet";
import { useNavigate } from "react-router-dom";

const theme = {
  global: {
    colors: {
      brand: "#228BE6",
    },
    font: {
      family: "Roboto",
      size: "18px",
      height: "20px",
    },
  },
};

const AppBar = (props) => (
  <Header
    background="brand"
    pad={{ left: "medium", right: "small", vertical: "small" }}
    elevation="medium"
    {...props}
  />
);

function Layout({ children }) {
  const { signOut, user } = useAuth();
  const redirect = useNavigate();
  const getAvatar = (_user) => {
    if (_user.user_metadata.avatar_url) {
      return <Avatar src={_user.user_metadata.avatar_url} />;
    } else {
      return <Avatar background="brand">{_user.email[0]}</Avatar>;
    }
  };
  const getName = (_session) => {
    if (_session.user_metadata.full_name) {
      return (
        <Text size="large" style={{ alignSelf: "center" }}>
          {_session.user_metadata.full_name}
        </Text>
      );
    } else {
      return (
        <Text size="large" style={{ alignSelf: "center" }}>
          {_session.email}
        </Text>
      );
    }
  };
  return (
    <Grommet theme={theme} full>
      <AppBar>
        <Box
          height="50px"
          width="50px"
          onClick={() => {
            redirect("/");
          }}
        >
          <Image fit="cover" src="/logo192.png" />
        </Box>
        <Box
          direction="row"
          gap="small"
          onClick={() => {
            redirect("/");
          }}
        >
          {getAvatar(user)}
          {getName(user)}
        </Box>
        <Button
          onClick={() => {
            signOut();
          }}
        >
          Sign Out
        </Button>
      </AppBar>
      {children}
    </Grommet>
  );
}

export default Layout;
