import QRCode from "react-qr-code";

function MyQr({ value }) {
  return (
    <div
      style={{
        height: "auto",
        margin: "0 auto",
        maxWidth: "100%",
        width: "100%",
      }}
    >
      <QRCode
        size={256}
        style={{
          height: "auto",
          maxWidth: "100%",
          width: "100%",
          maxHeight: "750px",
        }}
        value={value}
        viewBox={`0 0 256 256`}
      />
    </div>
  );
}

export default MyQr;
